<template>
  <label class="travelingCheck" v-if="personSeatData">
    <input type="checkbox" checked disabled/>
    <span class="travelingChecktag d-flex align-items-center justify-content-center">
      {{$t(`sabre.composition.${person.passengerType}`)}} {{ person.typeIndex + 1 }} - {{ personSeatData.seatNumber }}
    </span>
  </label>
  <label class="travelingCheck" v-else>
    <input type="checkbox" disabled/>
    <span class="travelingChecktag d-flex align-items-center justify-content-center">
      {{$t(`sabre.composition.${person.passengerType}`)}} {{ person.typeIndex + 1 }}
    </span>
  </label>
</template>

<script>

export default {
  name: 'one-person-ticket-info',
  props: {
    person: {
      type: Object,
      default: null,
    },
    seatData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    personSeatData() {
      const { person, seatData } = this;
      return seatData?.find((seat) => seat.passengerIndex === person.id) || null;
    },
  },
};
</script>
